<template>
  <div style="min-height: 100vh;overflow-y: scroll;background-color: #7B78FF;">
    <div class="week_bg" style="background-image: url(img/seventhMoon/w_bg.png);">
      <div class="week_date_bg" style="background-image: url(img/seventhMoon/w_date_bg.png);">
        <div class="week_date_title">{{actDate}}</div>
      </div>
      <div class="st_rule" style="background-image: url(img/seventhMoon/w_rule.png);" @click="goRule"></div>
    </div>
    
      <template>
        <!-- 活动进行中 -->
        <div v-if="mode == 1" class="week_gift display_flex justify-content_flex-justify align-items_center"
          style="background-image: url(img/seventhMoon/w_box1.png);">
          <div v-if="loading" class="w_loading">加载中...</div>
          <div class="w_item display_flex flex-direction_column justify-content_flex-center align-items_center"
            style="background-image: url(img/seventhMoon/w_box2.png);" v-for="(item, index) in giftInfo" :key="index">
            <div class="w_img display_flex justify-content_flex-center align-items_center">
              <img :src="item.gift_url" alt="">
            </div>
            <p style="color: #111111;">{{ item.gift_name }}</p>
            <div class="display_flex align-items_center" style="margin-top: 7px;">
              <span>{{ item.diamonds }}</span>
              <i style="background-image: url(img/bi.png);"></i>
            </div>
          </div>
        </div>
        <!-- 活动结束 -->
        <div v-if="mode == 2" class="week_giftOver display_flex justify-content_flex-center align-items_center"
          style="background-image: url(img/seventhMoon/w_boxOver1.png);">
          <div v-if="loading" class="w_loading">加载中...</div>
          <div class="w_itemOver display_flex flex-direction_column justify-content_flex-center align-items_center" v-for="(item, index) in giftInfo" :key="index">
            <div class="w_imgOver display_flex justify-content_flex-center align-items_center">
              <img :src="item.gift_url" alt="">
            </div>
            <p style="color:#9E5731 ;">{{ item.gift_name }}</p>
            <div class="display_flex align-items_center">
              <span style="color: #9E5731;">{{ item.diamonds }}</span>
              <i style="background-image: url(img/bi.png);"></i>
            </div>
          </div>
        </div>
      </template>

      <div class="week_rank"
        :style="{ backgroundImage: mode == 1 ? `url(img/seventhMoon/list_bg.png)` : `url(img/seventhMoon/list_over_bg.png)` }">
        <!-- <div class="week_title" style="background-image: url(img/seventhMoon/w_title.png);"></div> -->
        <div class="display_flex justify-content_flex-center align-items_center">
          <div class="week_nav"
          :style="{ backgroundImage: navCur == index ? `url(img/seventhMoon/navActive${index}.png)` : `url(img/seventhMoon/nav${index}.png)` }"
            v-for="(item, index) in nav" :key="index" @click="changeNav(index)"></div>
        </div>
        <div v-if="loading" class="w_loading">加载中...</div>
        <div class="week_top" style="background-image: url('img/seventhMoon/w_top3.png')" v-if="oneData">
          <!-- 第一名 -->
          <div class="week_one display_flex flex-direction_column align-items_center" v-if="oneData">
            <div class="top_one" style="background-image: url('img/seventhMoon/top_one.png')">
              <img class="one_head" :src="oneData.head_portrait" alt="">
            </div>
     
            <div class="week_name display_flex justify-content_flex-center align-items_center">
              <p>{{ oneData.nickname }}</p>
            </div>
            <div class="week_id">ID:{{ oneData.uid }}</div>
            <div class="week_value" v-if="mode == 1">{{ nav[navCur].value }}
              <span>{{ oneData.total_value }}</span>
            </div>
          </div>
          <!-- 第二名 -->
          <div class="week_two display_flex flex-direction_column align-items_center" v-if="twoData">
            <div class="top_two" style="background-image: url('img/seventhMoon/top_two.png')">
              <img class="two_head" :src="twoData.head_portrait" alt="">
            </div>
            <div class="week_nickname week_right">{{ twoData.nickname }}</div>
            <div class="week_id week_right">ID:{{ twoData.uid }}</div>
            <div class="week_value week_right" v-if="mode == 1">{{ nav[navCur].value }} {{ twoData.total_value }}</div>
          </div>
          <!-- 第三名 -->
          <div class="week_third display_flex flex-direction_column align-items_center" v-if="thirdData">
            <div class="top_two" style="background-image: url('img/seventhMoon/top_three.png')">
              <img class="two_head" :src="thirdData.head_portrait" alt="">
            </div>
   
            <div class="week_nickname week_left">{{ thirdData.nickname }}</div>
            <div class="week_id week_left">ID:{{ thirdData.uid }}</div>
            <div class="week_value week_left" v-if="mode == 1">{{ nav[navCur].value }} {{ thirdData.total_value }}</div>
          </div>
        </div>
        <!-- 第四名往后 -->
        <div class="week_listMg" v-if="mode == 1">
          <div class="week_view display_flex justify-content_flex-justify align-items_center" v-for="(item, index) in list"
            :key="index">
            <div class="display_flex align-items_center">
              <div class="week_three">{{ index + 4 }}</div>
              <img class="week_head" :src="item.head_portrait" alt="">
              <div class="week_people">
                <p>{{ item.nickname }}</p>
                <span>ID:{{ item.uid }}</span>
              </div>
            </div>
            <div class="week_cp margin-right-sm">
              <p>{{ item.total_value }}</p>
              <span>{{ nav[navCur].value }}</span>
            </div>
          </div>
        </div>
        <!-- 自己排行 -->
        <div class="week_my" v-if="my_value > 0">我距离第十名{{ nav[navCur].value }}：{{ my_value }}</div>
        <!-- 无数据 -->
        <div v-if="noData" class="noData">
          <img src="img/noData.png" alt="">
        </div>
      </div>
      <div class="week_beself">本活动最终解释权归多乐所有</div>
    </div>
</template>

<script>
import "@/assets/css/base.css"
import "@/assets/css/seventh.css"
import { ActivityWeek } from "@/api/api";

export default {
  name: 'weekStarRank',
  data() {
    return {
      nav: [
        {
          name: '周星魅力榜',
          value: '魅力值'
        },
        {
          name: '周星财富榜',
          value: '财富值'
        }],
      navCur: 0,
      list: [],
      giftInfo: [],
      loading: true,
      my_value: null,
      noData: false,
      oneData: null,
      twoData: null,
      thirdData: null,
      mode: 1,
      actDate: null
    }
  },
  created() {
    let access_token = this.$route.query.access_token
    localStorage.setItem('access_token', access_token)
    this.getData()
  },
  methods: {
    getData() {
      let type = this.navCur + 1
      ActivityWeek({ type }).then(response => {
        this.loading = false
        let [oneData = null, twoData = null, thirdData = null, ...list] = response.data.data.data
        this.oneData = oneData
        this.twoData = twoData
        this.thirdData = thirdData
        this.list = list
        this.actDate = response.data.date
        this.my_value = response.data.data.dis_value
        if(response.data.mode==2){
          this.giftInfo = [response.data.data.giftInfo]
        }else{
          this.giftInfo = response.data.data.giftInfo
        }
      
        this.mode = response.data.mode
        if (response.data.data.data.length == 0) {
          this.noData = true
        }
      },
        error => {
          this.loading = false
          this.noData = true
          this.$toast.fail(error)
        }).catch((error) => {
          this.loading = false
          this.noData = true
          this.$toast.fail(error)
        })
    },
    changeNav(e) {
      this.navCur = e
      this.getData()
    },
    goRule() {
      this.$router.push({ path: '/weekStarRule' })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
